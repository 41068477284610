/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const readableUpdateJobItem = /* GraphQL */ `
  subscription ReadableUpdateJobItem($id: ID!, $itemId: ID) {
    readableUpdateJobItem(id: $id, itemId: $itemId) {
      id
      identity
      input
      itemId
      modelId
      order
      output
      owner
      parent
      status
      type
    }
  }
`;
export const readableUpdateJobMetadata = /* GraphQL */ `
  subscription ReadableUpdateJobMetadata($id: ID!) {
    readableUpdateJobMetadata(id: $id) {
      createdAt
      id
      name
      owner
      updatedAt
    }
  }
`;
