/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const readableCreateJob = /* GraphQL */ `
  mutation ReadableCreateJob($identity: String!) {
    readableCreateJob(identity: $identity) {
      id
      identity
    }
  }
`;
export const readableCreateJobItem = /* GraphQL */ `
  mutation ReadableCreateJobItem(
    $id: ID!
    $identity: String!
    $order: Int!
    $parent: String
    $type: String!
  ) {
    readableCreateJobItem(
      id: $id
      identity: $identity
      order: $order
      parent: $parent
      type: $type
    ) {
      id
      identity
      itemId
      order
      parent
      type
    }
  }
`;
export const readableUpdateJobItem = /* GraphQL */ `
  mutation ReadableUpdateJobItem(
    $id: String!
    $identity: String
    $input: String
    $itemId: String!
    $modelId: String
    $order: Int
    $output: String
    $parent: String
    $status: String
    $type: String
  ) {
    readableUpdateJobItem(
      id: $id
      identity: $identity
      input: $input
      itemId: $itemId
      modelId: $modelId
      order: $order
      output: $output
      parent: $parent
      status: $status
      type: $type
    ) {
      id
      identity
      input
      itemId
      modelId
      order
      output
      owner
      parent
      status
      type
    }
  }
`;
export const readableUpdateJobMetadata = /* GraphQL */ `
  mutation ReadableUpdateJobMetadata($id: String!, $name: String) {
    readableUpdateJobMetadata(id: $id, name: $name) {
      createdAt
      id
      name
      owner
      updatedAt
    }
  }
`;
export const sharedUpdatePreferences = /* GraphQL */ `
  mutation SharedUpdatePreferences(
    $visualDensity: String
    $visualMode: String
  ) {
    sharedUpdatePreferences(
      visualDensity: $visualDensity
      visualMode: $visualMode
    ) {
      visualDensity
      visualMode
    }
  }
`;
export const translationCreateJob = /* GraphQL */ `
  mutation TranslationCreateJob(
    $input: translation_createJob_input
    $limit: Int
    $nextToken: String
  ) {
    translationCreateJob(input: $input, limit: $limit, nextToken: $nextToken) {
      contentType
      createdAt
      id
      jobIdentity
      jobName
      jobOwner
      jobStatus
      languageSource
      languageTargets
      sourceKey
      sourceStatus
      translateCallback
      translateKey
      translateStatus
    }
  }
`;
